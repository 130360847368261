import { Box, Popover, PopoverBody, PopoverContent, useDisclosure } from '@chakra-ui/react';
import React from 'react';

import SettingsColorTheme from './SettingsColorTheme';
import SettingsIdentIcon from './SettingsIdentIcon';

const Settings = () => {
  const { isOpen, onClose } = useDisclosure();

  return (
    <Popover placement="bottom-start" trigger="click" isOpen={ isOpen } onClose={ onClose }>
      { /* <PopoverTrigger>
        <IconButton
          variant="simple"
          colorScheme="orange"
          aria-label="User settings"
          icon={ <IconSvg name="gear_slim" boxSize={ 5 }/> }
          p="1px"
          boxSize={ 5 }
          onClick={ onToggle }
        />
      </PopoverTrigger> */ }
      <PopoverContent overflowY="hidden" w="auto" fontSize="sm">
        <PopoverBody boxShadow="2xl" p={ 4 }>
          <SettingsColorTheme/>
          <Box borderColor="divider" borderWidth="1px" my={ 3 }/>
          <SettingsIdentIcon/>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default React.memo(Settings);
