import { Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import type { CustomLinksGroup } from 'types/footerLinks';

import config from 'configs/app';
import type { ResourceError } from 'lib/api/resources';
import useFetch from 'lib/hooks/useFetch';

import FooterLinkItem from './FooterLinkItem';

const MAX_LINKS_COLUMNS = 4;

const Footer = () => {
  const BLOCKSCOUT_LINKS = [
    {
      icon: 'social/git' as const,
      iconSize: '26px',
      url: 'https://github.com/MazzeLabs',
    },
    {
      icon: 'social/twitter' as const,
      iconSize: '26px',
      url: 'https://twitter.com/MazzeLabs',
    },
    {
      icon: 'social/telegram_filled' as const,
      iconSize: '26px',
      url: 'https://t.me/MazzeLabs',
    },
  ];

  const fetch = useFetch();

  const { isPlaceholderData, data: linksData } = useQuery<unknown, ResourceError<unknown>, Array<CustomLinksGroup>>({
    queryKey: [ 'footer-links' ],
    queryFn: async() => fetch(config.UI.footer.links || '', undefined, { resource: 'footer-links' }),
    enabled: Boolean(config.UI.footer.links),
    staleTime: Infinity,
    placeholderData: [],
  });

  const colNum = isPlaceholderData ? 1 : Math.min(linksData?.length || Infinity, MAX_LINKS_COLUMNS) + 1;

  return (
    <Stack direction={{ base: 'column', lg: 'row' }} justifyContent="space-between" alignItems="center" paddingX="15px" marginY="20px">
      <div>
        <span style={{ fontSize: '28px', fontWeight: 700 }}><span style={{ color: 'rgb(253, 145, 9)' }}>Mazze</span>Scan</span>
      </div>
      <span>MazzeScan © 2024 - Developed by MazzeLabs</span>

      <Stack direction="row" gap={ 1 }>
        {
          ([
            { title: 'Mazze Scan', links: BLOCKSCOUT_LINKS },
          ])
            .slice(0, colNum)
            .map(linkGroup => (
              <Stack direction="row" key={ linkGroup.title }>
                { linkGroup.links.map(link => <FooterLinkItem { ...link } key={ link.url }/>) }
              </Stack>
            ))
        }
      </Stack>
    </Stack>
  );
};

export default React.memo(Footer);
