import { Box } from '@chakra-ui/react';
import React from 'react';

import { route } from 'nextjs-routes';
interface Props {
  isCollapsed?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
}

const NetworkLogo = ({ isCollapsed, onClick }: Props) => {
  return (
    <Box
      as="a"
      href={ route({ pathname: '/' }) }
      width="80%"
      height={{ base: '24px', lg: isCollapsed === false ? '24px' : '30px', xl: isCollapsed ? '50px' : '50px' }}
      display="flex"
      justifyContent="center"
      overflow="hidden"
      onClick={ onClick }
      flexShrink={ 0 }
      aria-label="Link to main page"
    >
      <Box
        width={{ base: '24px', lg: isCollapsed ? '70px' : '50px' }}
        height={{ base: '24px', lg: isCollapsed ? '70px' : '50px' }}
      >
        { /* eslint-disable-next-line @next/next/no-img-element */ }
        <img src="/favicon/logo.svg" alt="logo" width="100%" height="100%"/>
      </Box>
      { /* { isCollapsed ?
        <span style={{ fontSize: '24px', fontWeight: 700, color: 'rgb(253, 145, 9)' }}>M</span> :
        <span style={{ fontSize: '20px', fontWeight: 700 }}><span style={{ color: 'rgb(253, 145, 9)' }}>Mazze</span>Scan</span> } */ }
    </Box>
  );
};

export default React.memo(NetworkLogo);
